import * as React from "react";
import { graphql, HeadFC } from "gatsby";
import { Form } from "../components/elements/Form/Form";
import Layout from "../components/layouts/Default/Default";
import { CommonHead } from "../components/commons/Head/Head";

export const query = graphql`
  query {
    allSettingsJson {
      nodes {
        id
        siteTitle
      }
    }
  }
`;

const ApplicationFormPage = ({ data, location }: any) => {
  const title = data.allSettingsJson?.nodes[0].siteTitle;
  const pageTitle = "オープンキャンパスお申し込み";
  const lead =
    "説明会のお申込み、その他お問い合わせは以下の申込フォームからお願いします。 後ほどこちらからご連絡しますので、メールアドレス・電話番号を必ず記入をお願いします。";

  return (
    <Layout title={title} pageType="SubPage" displayBottomNav={false}>
      <div
        className="tw-c-applicationFormPage md:tw-c-applicationFormPage-md"
        data-hide-bottomNav
      >
        <Form
          location={location}
          pageTitle={pageTitle}
          lead={lead}
          formType="application"
        ></Form>
      </div>
    </Layout>
  );
};

export default ApplicationFormPage;

export const Head: HeadFC = ({ data }: any) => {
  return (
    <CommonHead
      ogTitle={`オープンキャンパスお申し込み - ${data.allSettingsJson?.nodes[0].siteTitle}`}
    >
      <title>
        オープンキャンパスお申し込み -{" "}
        {data.allSettingsJson?.nodes[0].siteTitle}
      </title>
    </CommonHead>
  );
};
